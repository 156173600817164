
.allura-regular {
    font-family: "Allura", cursive;
    font-weight: 400;
    font-style: normal;
  }

  .quicksand-300 {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }

  .uppercase-text {
    text-transform: uppercase;
  }

  .white-text {
    color: white;
  }

  .center {
    text-align: center; /* Center align the text */
  }

.timelines-list-container {
    background: #ffa5a5	; 
    padding: 20px;
  }
  
  .timelines-list-container h1 {
    color: white;
    font-family: "Allura", cursive;
    font-weight: 400;
    font-style: normal;
    text-align: center; /* Center align the text */
    font-size: 4em; /* Adjust the font size as needed */
    margin-block-end: -0.3em;
  }

  .subheading {
    margin-bottom: 50px;
  }


  .timelines-list-container ul {
    list-style-type: none;
    padding: 0;
  }
  


  .timelines-list-container ul li img {
    width: 200px; /* Set fixed width for the images */
    border: 2px solid #333; /* Add a border */
    border-radius: 10px; /* Add border radius for a rounded border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Allura&display=swap')
  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap')
