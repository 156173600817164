.timeline-entry {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; /* Add space between timeline entries */
  }
  
  .timeline-text {
    text-align: center; /* Center align text */
    margin-right: 20px; /* Add space between text and image */
    margin-left: 20px; /* Add space between text and image */
    max-width: 300px;
  }
  
  .inline-block {
    display: inline-block; /* Ensure the container takes only the width of the text */
  }
  
  .date-separator {
    display: block;
    height: 1.5em; /* Adjust line spacing after the date */
  }
  
  .timeline-image {
    max-width: 55%; /* Set max width for the image */
  }

  .timeline-date {
    margin-block-end: 0.5em;
    border-bottom: 0.8px solid white; /* White bottom border */
    padding-bottom: 6px;
    font-size: 0.8em;

  }

  .timeline-title {
    margin-block-end: 0em;

  }

  .timeline-desc {
    margin-block-start: 0.5em;
  }