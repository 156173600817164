@keyframes heart {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.6;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  .heart-animation {
    position: fixed; /* Position the hearts relative to the viewport */
    font-size: 40px; /* Adjust the size of the heart */
    color: red; /* Color of the heart */
    animation: heart 1s ease-out; /* Apply the heart animation */
  }
  